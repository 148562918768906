import { CreateUserApi } from "./user";


export const users: CreateUserApi[] = [
  {username: 'foobar', email: 'foobar@gmail.com', password: 'password_foobar'},
  {username: 'wbucket', email: 'wbucket@gmail.com', password: 'password_wbucket'},
  {username: 'other_friend', email: 'other_friend@gmail.com', password: 'password_other_friend'},
  {username: 'naomi123', email: 'naomi123@gmail.com', password: 'password_naomi123'},
  {username: 'kaoskody', email: 'kaoskody@gmail.com', password: 'password_kaoskody'},
  {username: 'haha', email: 'haha@gmail.com', password: 'password_haha'},
];