
.user {
  width: 25px;
}

.user.red {
  background-color: red;
}

.user.blue {
  background-color: blue;
}

.user.green {
  background-color: green;
}

.user.purple {
  background-color: purple;
}

.user.yellow {
  background-color: yellow;
}

.user.black {
  background-color: black;
}

.user.brown {
  background-color: brown;
}