.hex-grid {
  background-color: black;
  padding-bottom: 100px;
}

.hex-row {
  display: flex;
  flex-direction: row;
}

.hex-container {
  display: flex;
  margin-right: -29px;
  flex-direction: row;
  margin-bottom: 1px;
  position: relative;
}

.hex {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 120px;
}

.good-block {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 5px;
  width: 100%;
}

.good {
  width: 25px;
  height: 25px;
  background-color: var(--color);
  border: solid 2px grey;
}

.good:not(:first-child) {
  margin-left: -10px;
}


.unpassable {
  --color: grey;
}

.red {
  --color: red;
}

.blue {
  --color: blue;
}

.purple {
  --color: purple;
}

.yellow {
  --color: yellow;
}

.black {
  --color: black;
}

.plain {
  --color: green;
}

.river {
  --color: lightblue;
}

.mountain {
  --color: brown;
}

.hex-container:nth-child(even) {
  position: relative;
  top: 53px;
}

.hex-right {
  border-left: 30px solid var(--color);
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
}

.hex-body {
  width: 60px;
  height: 104px;
  background-color: var(--color);
}

.hex-left {
  border-right: 30px solid var(--color);
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
}

.town {
  position: absolute;
  top: 25px;
  left: 36px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
}

.hex-name {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  background-color: lightgrey;
}

.tile {
  position: absolute;
  width: 100%;
  height: 100%;
}